@import url(fonts/fonts.scss);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

html, body {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

body {
  
 /* Prevents content jumping,
    such as when hiding the toolbar in the Samsung browser. */
  overflow: hidden;

  overscroll-behavior: none;  // disables page overscroll, for example in Microsoft Edge
  font-family: -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

img {
  pointer-events: none;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-user-drag: none;
}
