@keyframes float {
  33% {
    transform: translate(-15px, 40px) rotateZ(-8deg) scale(1.025);
  }
}

@keyframes float-2 {
  33% {
    transform: translate(-10px, -25px) rotateZ(-3deg) scale((.975));
  }
}

.App {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background: linear-gradient(160deg, #FFC9C9, #FFF, #A3EBF7);
  background-size: cover;
  overflow: hidden;

  .background {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    height: 100%;
  }

  .left-side, .right-side {
    display: flex;
    height: 100%;
    width: 50%;
  }

  .left-side {
    position: relative;
    align-items: center;
    justify-content: flex-end;
    
    .mockup-container {
      z-index: 2;
      position: absolute;
      right: -1.5vmin;

      &__bg-images {
        display: contents;

        * {
          z-index: -1;
          position: absolute;
        }

        .bg-images__womens-clothes {
          top: -4vmin;
          right: -3vmin;
          height: 25vmin;
          filter: blur(2px);
          animation: float 20s ease-in-out infinite;
        }
            
        .bg-images__t-shirts {
          top: -2vmin;
          left: -1vmin;
          height: 30.25vmin;
          filter: blur(4px);
          animation: float-2 15s ease-in-out infinite;
        }
      }

      &__mockup-image {
        display: block;
        max-width: calc(100% + 18vmin);
        height: 112.5vmin;
        margin-bottom: -27.5vmin;
        margin-left: -18vmin;
        object-fit: contain;
      }
    }
  }

  .right-side {
    flex-direction: column;
    justify-content: center;
    gap: 4.5vmin;
    padding-right: 13vmin;

    .headline {
      z-index: 1;
      width: fit-content;
      font-family: 'Monument Extended';
      font-size: 5vmin;
      font-weight: 600;
      color: #494949;

      a {
        display: inline-block;
        color: #33AFED;
        transition: transform .25s ease-out;

        &:hover {
          transform: translateY(-.75vmin) scale(1.0);
        }
      }
    }

    .post-container {
      z-index: 1;
      position: relative;
      display: flex;
      // min-height: 46.5vmin;
      max-width: 100%;
      // height: 52vmin;
      height: fit-content;
      width: fit-content;

      .parallax-container {
        position: absolute;
        right: -3vmin;
        top: -3.5vmin;
        width: fit-content;

        .money-with-wings {
          width: 11vmin;
        }
      }
      
      &__post {
        width: 53vmin;
        padding: 1.75vmin 2.25vmin;
        border-radius: 3%;
        background-color: #FFFFFF;
        border: 1px solid #33afed17;
        word-break: break-word;
        white-space: break-spaces;
        font-family: 'Roboto', 'Arial';
        font-size: 1.9vmin;
        // font-size: 16px;
        // font-family: 'Arial';
        // line-height: 17.5px;
        color: #212121;
        box-shadow: 5px 10px 20px #00000026;

        * a {
          &:hover {
            text-decoration: underline;
          }
        }

        .post__channel-name {
          display: inline-block;
          margin-bottom: 1vmin;
          color: #343639;
          font-weight: 500;

          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }

        .post__image {
          display: block;
          max-height: 100%;
          max-width: 100%;
          height: auto;
          width: 100%;
          margin-top: .75vmin;
          border-left: .3vmin solid #343638;
          padding: .75vmin 0 .75vmin 1vmin;
        }

        .post__meta {
          display: flex;
          justify-content: space-between;
          gap: 1vmin;
          margin-top: .5rem;
          font-size: .875em;

          a {
            color: #343638;
          }
          
          span {
            color: #738ca7;
          }
        }
      }

      &__bg-images {
        display: contents;
        
        .bg-images__person {
          z-index: -1;
          position: absolute;
          width: 15vmin;
          right: -12vmin;
          top: 17vmin;
        }
      }
    }
  }
}
