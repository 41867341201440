@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended'), url(MonumentExtended/MonumentExtended-Ultrabold.otf) format('opentype');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(Roboto/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(Roboto/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-display: swap;
}
